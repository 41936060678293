.alumnos__libros {
	display: grid;
	grid-template-columns: minmax(320px, 1fr) 4fr;
	max-height: 100%;
	gap: 1rem;
}
@media (max-width: 768px) {
	.alumnos__libros {
		grid-template-columns: 1fr;
        grid-template-rows: repeat(2, minmax(620px, 1fr));
	}
}

.alumnos__libros .wrapper__indice {
	background-color: #fff;
	padding: 22px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	max-height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	gap: 16px;
}
@media (max-width: 768px ) { 
    .alumnos__libros .wrapper__indice {
    }
}


.alumnos__libros .wrapper__ejercicio {
	padding: 12px 18px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	background-color: #fff;
	border-radius: 10px;
	width: 100%;
	height: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.alumnos__libros .wrapper__ejercicio .btn__start {
	background-color: var(--color-primary);
	color: #fff;
	padding: 6px 10px;
	font-weight: 500;
	border-radius: var(--border-radius-input);
	transition: all 0.25s ease-in-out;
}

.alumnos__libros .wrapper__ejercicio .btn__start:hover {
	cursor: pointer;
	background-color: var(--color-secundary);
}
