.component__mostrarMensajePre {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.component__mostrarMensajePre .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.component__mostrarMensajePre .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    margin-bottom: 8px;
}

.component__mostrarMensajePre .title .icon svg {
    color: var(--color-primary);
    font-size: 48px;
}

.component__mostrarMensajePre .title h2{
    color: var(--color-primary);
    text-align: center;
}

.component__mostrarMensajePre .content p{
    font-weight: 500;
    opacity: 0.8;
    text-align: center;
}


/* NoSelectedExercise Component */

.component__noSelectedExercise {
    display: grid;
    place-content: center;
    height: 100%;
}



.component__noSelectedExercise .title h2 {
    text-align: center;
    color: var(--color-text-opacity);
    font-size: 16px;
}