.component__recursos {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-items: stretch;
	gap: 5%;
}

@media screen and (max-width: 720px) {
	.component__recursos {
		display: grid;
		grid-template-columns: 1fr;
	}
}

.component__recursos .wrapper_recursos .container-items {
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	max-height: 70vh;
	padding: 16px 0;
}

/* .component__recursos .container-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 0;
}

.component__recursos > div:first-child{
   
} */

.component__recursos .viewer-pdf {
	width: 100%;
}

/* Component Item Recurso  */

.component__itemRecurso {
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
	display: flex;
	flex-direction: row;
	gap: 2rem;
	align-items: center;
	padding: 14px;
	border-radius: var(--border-radius-input);
	max-width: 680px;
}

.component__itemRecurso .icon img {
	width: 50px;
}

@media screen and (max-width: 648px) {
	.component__itemRecurso .icon img {
		width: 36px;
	}
}

.component__itemRecurso .content-item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.component__itemRecurso .content-item .description {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.component__itemRecurso .content-item .description .title {
	font-weight: 600;
}

.component__itemRecurso .content-item .description .tags {
	font-size: 13px;
	font-weight: 500;
	color: var(--color-subtitle);
}

.component__itemRecurso .content-item .options-item {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.component__itemRecurso .content-item .options-item .buttons button {
	background-color: transparent;
	padding: 6px 8px;
	border: 1px solid #e0e0e0;
	font-weight: 500;
	font-size: 13px;
	transition: all 0.25s ease-in-out;
}

.component__itemRecurso .content-item .options-item .buttons button:hover {
	cursor: pointer;
}

.component__itemRecurso .content-item .options-item .buttons button.download {
	border-top-left-radius: var(--border-radius-input);
	border-bottom-left-radius: var(--border-radius-input);
}

.component__itemRecurso .content-item .options-item .buttons button.download:hover {
	background-color: #229954;
	color: #fff;
	border-color: transparent;
}

.component__itemRecurso .content-item .options-item .buttons button.view {
	border-top-right-radius: var(--border-radius-input);
	border-bottom-right-radius: var(--border-radius-input);
}

.component__itemRecurso .content-item .options-item .buttons button.view:hover {
	background-color: #d35400;
	color: #fff;
	border-color: transparent;
}

/* Libro digital */

.accordion__book {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.accordion__book .accordion__heading {
}

.accordion__book .accordion__heading .accordion__button,
.accordion__book .accordion__panel {
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
	padding: 14px;
	border-radius: var(--border-radius-input);
	max-width: 680px;
	font-size: 14px;
	font-weight: 600;
}

.accordion__book .accordion__panel .buttons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.accordion__book .accordion__panel .view {
	background-color: transparent;
	padding: 6px 8px;
	border: 1px solid #e0e0e0;
	font-weight: 500;
	font-size: 13px;
	transition: all 0.25s ease-in-out;
}

.accordion__book .accordion__panel .view:hover {
	background-color: #d35400;
	color: #fff;
	border-color: transparent;
	cursor: pointer;
}

.accordion__book .accordion__panel {
	margin-top: 12px;
	margin-left: 12px;
}

.accordion__book .accordion__item {
	border: 0;
}

.accordion__book .accordion__button:before {
	width: 6px;
	height: 6px;
}

.component__headerProfesor.header {
	background: linear-gradient(347deg, #02002490 0%, #001f5490 0%, #00d5ff90 100%),
		url('../../assets/images/portada-example.jpg'); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	display: flex;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	justify-content: space-between;
	padding: 0 100px;
	height: 70px;
	align-items: center;
}
@media (max-width: 768px ) { 
	.component__headerProfesor.header{
		padding: 0;
	}
}
.component__headerProfesor.header button.profile:hover {
	background-color: rgba(255, 255, 255, 0.13);
	cursor: auto;

}
