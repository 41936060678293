/* Component Indice */

.component__indiceAlumno {
    height: 100%;
    overflow: auto;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.component__indiceAlumno .content__indice{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.component__indiceAlumno::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.component__indiceAlumno::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}

.component__indiceAlumno::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

.component__indiceAlumno::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
}

.component__indiceAlumno::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, .9);
}

.component__indiceAlumno > .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.component__indiceAlumno .title p {
    font-size: 15px;
    font-weight: 600;
    color: var(--color-text-opacity);
}


/* Component ItemUnidad */ 

.loadingItems {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

div.c__itemUnidad{
    position: relative;
    padding: 18px 24px;
    background-color: #2c64ff18;
    border-radius:4px;
    min-height: 60px;
}

div.c__itemUnidad::before {
    content: '';
    background-color: var(--color-primary);
    position: absolute;
    left: 0;
    top: 0;
    width: 0.5rem;
    height: 100%;
    border-radius: 4px 0 0 4px;
}

.c__itemUnidad summary .title {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.c__itemUnidad summary .title > span {
    text-transform: uppercase;
}

.c__itemUnidad summary .title .score{
    font-size: 11px;
}

.c__itemUnidad summary .title .total-topics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.c__itemUnidad summary .title .total-topics span:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    opacity: 0.7;
    font-size: 12px;
}

.c__itemUnidad .items-topics {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 6px,
}

.c__itemUnidad .items::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background-color: #F5F5F5;
}

.c__itemUnidad .items::-webkit-scrollbar {
    width: 3px;
    background-color: #F5F5F5;
}

.c__itemUnidad .items::-webkit-scrollbar-thumb {
    border-radius: 20px;   
    background-color: rgb(136, 134, 134);
}

.c__itemUnidad summary {
    list-style: none;
    cursor: pointer;
    position: relative;
}

.c__itemUnidad summary::-webkit-details-marker {
    content: '';
    display: none;
}

.c__itemUnidad summary::-webkit-details-marker {
    display: none !important;
}

.c__itemUnidad summary span {
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    text-transform: lowercase;
}

.c__itemUnidad summary span::first-letter {
    text-transform: uppercase;
}

.c__itemUnidad[open]>summary:before {
    transform: rotate(90deg);
    transition: 0.45s transform ease;
}

/* Component ItemTema */
.c__itemTema {
    margin-bottom: 12px;
}

.c__itemTema .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
}

.c__itemTema .title .tema {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-weight: 600;
}

.c__itemTema .title .tema span {
    text-transform: lowercase;
    font-weight: 500;
}

.c__itemTema .title .tema span::first-letter {
    text-transform: uppercase !important;
}

.c__itemTema .title .status span.in-progress{
    background-color: var(--color-btn-success);
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    padding: 2px 6px;
    border-radius: 4px;
    white-space: nowrap;
}

.c__itemTema .title .status .score {
    font-size: 12px;
    font-weight: 600;
}

