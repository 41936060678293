.component__helpComponent {
    display: inline-block;
    z-index: -2;
    
}

.component__helpComponent span {
    display: grid;
    place-content: center;
    background-color: #313131;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    opacity: 0.7;
}

.component__helpComponent span:hover {
    cursor: pointer;
}

.component__helpComponent span>svg {
    color: #fff;
    font-size: 60%;
}

.popup-help-arrow {
    color: var(--color-primary);
}

[role='tooltip'].popup-help-content {
    background-color: var(--color-primary);
    max-width: 220px;
    border-radius: 5px;
}

[role='tooltip'].popup-help-content .content {
    padding: 8px 12px;
}

[role='tooltip'].popup-help-content .content .title {
    font-size: 15px;
    color: #fff;
    font-weight: 600;
}

[role='tooltip'].popup-help-content .content p{
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    line-height: 1.3;
}