.list__alumnos{
    width: 100%;
    max-height: 100%;
}


.component__tableAlumnos .table__wrapper .search-options {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    
}

@media screen and (max-width: 768px) {
    .component__tableAlumnos .table__wrapper .search-options  {
        flex-direction: column-reverse;
        
    }
}

/* Table */

.table__alumnos {
    width: 100%;
    min-height: 100%;
    border-collapse: collapse;
}

.table__alumnos tbody {
    display: block;
    height: 70vh;
    overflow: auto;
    padding-right: 10px;
}
.table__alumnos thead, .table__alumnos tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.table__alumnos thead {
    width: calc( 100% - 1em )
}

.table__alumnos thead tr {
    text-align: left;
    padding: 10px;
}

.table__alumnos thead tr th{
    padding: 12px 8px;
    /* color: var(--color-subtitle); */
    font-weight: 700;
    font-size: 14px;
}

.table__alumnos tbody tr {
    position: relative;
    width: 100%;
    font-size: 13px;
    transition: all .20s ease-in-out;
}


.table__alumnos tbody tr td{
    background-color: #fff;
    border: 6px solid transparent;
    border-right: 0;
    border-left: 0;
    padding: 18px 18px;
    width: 100%;
}

.table__alumnos tbody tr:hover {
    cursor: pointer;
}


.table__alumnos tr td:first-child {
    border-left-style: solid;
    border-top-left-radius: 5px; 
    border-bottom-left-radius: 5px;
}
.table__alumnos tr td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 5px; 
    border-top-right-radius: 5px; 
}

.table__alumnos tbody tr::after {
   content: '';
   position: absolute;
   width: 4px;
   left: 0;
   height: 100%;
   top: 0;
   border-top-left-radius: 5px; 
   border-bottom-left-radius: 5px;
   transition: background-color .1s ease-in-out;
}

.table__alumnos tbody tr:hover::after{
    background-color: var(--color-primary);
}

.table__alumnos tbody tr:hover{
    transform: translate(0px, -2px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.table__alumnos tbody tr td .tag {
    color: #fff;
    padding: 4px 8px;
    border-radius: 10px;
    font-weight: 600;
}

.table__alumnos tbody tr td .tag.enabled {
    background-color: #DDF8ED;
    color: #51B0AC;
}

.table__alumnos tbody tr td .tag.disabled {
    background-color: #F9E7E5;
    color: #C23228;
}

.table__alumnos tbody tr td .bold {
    font-weight: 600;
    font-size: 14px;
}

@media screen and (max-width: 768px) {

    .table__alumnos thead tr {
        display: none;
    }

    .table__alumnos tbody {
        margin-top: 16px;
    }

    .table__alumnos tbody tr {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 12px;
    }

    .table__alumnos tbody tr {
        padding: 14px 12px;
        background-color: #fff;
        position: relative;
    }

    .table__alumnos tbody tr td {
        position: relative;
        padding: 0;
        
        display: inline-block;
        width: auto;
    }

    .table__alumnos tbody tr td::before {
        content: attr(data-label);
        font-weight: bold;
        text-transform: capitalize;
        margin-right: 8px;
        float: left;
        font-size: 12px;
    }

    .table__alumnos tbody tr td[data-label="Estatus"] {
        position: absolute;
        width: auto;
        right: 0px;
    }

    .table__alumnos tbody tr td[data-label="Estatus"]::before{
        content: '';
    }
}


/* Global Filter */
.component__globalFilter{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    background-color: #fff;
    max-width: 260px;
    padding: 10px 12px;
    border-radius: var(--border-radius-input);
    border: 1px solid #eee;
    
}

.component__globalFilter:hover svg {
    color: var(--color-primary);
}

.component__globalFilter svg {
    font-size: 16px;
    color: var(--color-subtitle);
}

.component__globalFilter input[type="text"] {
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
}

.component__globalFilter input[type="text"]:focus::placeholder {
    color: transparent;
}

/* Select Filter */
.component__selectFilter {
    height: 100%;
    width: 100%;
    padding: 8px 12px;
    border-radius: var(--border-radius-input);
    border: 1px solid #eee;
    font-size: 14px;
    font-weight: 500;
}

.component__selectFilter option {
    font-weight: 500;
    font-size: 14px;
    padding: 4px 0;
}

.component__selectFilter:hover{
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    cursor: pointer;
}

/* Dropdown Wrapper */

.float-dropdown-content{
    background-color: #fff;
    border-radius: 5px;
    width: 200px;
    padding: 12px 14px;
    border: 1px solid #eee;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.float-dropdown-content .mobile .item{
    margin-bottom: 12px;
}

.float-dropdown-content .mobile .item label{
    margin-bottom: 4px;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    color: var( --color-subtitle);
}

.float-dropdown-content .mobile .item .component__selectFilter {
    padding: 4px 6px;
    font-size: 13px;
    background-color: #fff;
}

.table__wrapper .search-options .dropdown{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.table__wrapper .search-options .dropdown .open-mobile{
    height: 90%;
    background-color: var(--color-primary);
    display: grid;
    place-content: center;
    color: #fff;
    font-size: 24px;
    width: 38px;
    border-radius: 3px;
}

.table__wrapper .search-options .dropdown .open-mobile:hover{
    background-color: var(--color-primary-hover);
    cursor: pointer;
}

.table__wrapper .search-options .dropdown .desktop {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.table__wrapper .search-options .dropdown .desktop .item{
    max-width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.table__wrapper .search-options .dropdown .item label{
    font-weight: 600;
    font-size: 14px;
}

.table__wrapper .search-options .export-to{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    background-color: #1F6E43;
    color: #fff;
    border-radius: var(--border-radius-input);
    transition: all .30s ease-in-out;
    min-width: 38px;
    justify-content: center;
}   

.table__wrapper .search-options .export-to:hover{
    cursor: pointer;
    background-color: #156639;
}

.table__wrapper .search-options .export-to span{
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    
}

@media screen and (max-width: 768px) {
    .table__wrapper .search-options .dropdown .desktop{
        display: none;
    }

    .table__wrapper .search-options .export-to span {
        display: none;
    }

    .table__wrapper .search-options .export-to svg {
        font-size: 1.5rem;
    }
    .component__globalFilter{
        max-width: 100%;

    }

}

@media screen and (max-height: 736px) {
    .table__alumnos{
        overflow: scroll;
    }

    .table__alumnos tbody {
        display: block;
        height: 100%;
        overflow: auto;
    }
    .table__alumnos thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
}