.tooltip-arrow{
   display: none;
}

.content_ToolTip {
    background-color: #333333;
    padding: 6px 10px;
    border-radius: 10px;
}

.content_ToolTip p{
    color: #fff;
    font-size: 12px;
    
}