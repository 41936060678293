/* Component BombaTiempo*/

.component__bombaTiempo {
    display: inline-block;
    width: auto;
}

.component__bombaTiempo .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background-color: rgb(0,0,0,0.04);
    padding: 8px 12px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.0) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.component__bombaTiempo .content p {
    color: var(--color-text-opacity);
    font-size: 13px;
    font-weight: 700;
}

/* Component Timer */

.component__timer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.component__timer span {
    color: var(--color-primary);
    font-weight: 700;
    font-size: 24px;
    font-family: 'Ubuntu', sans-serif;
}

.component__timer span > .time-off{
    font-size: 17px;
}