.profesor__main {
    display: flex;
    flex-direction: column;
    background-color: var(--background-color-secundary);
}

.profesor__main .main__content {
    width: 100%;
    overflow: auto;
    padding: var(--padding-content);
    height: 100%;
}

@media screen and (max-width: 1440px) {
    .profesor__main .main__content {
        padding: 2.5rem 3rem;
    }
}

@media screen and (max-width: 480px){
    .profesor__main .main__content {
        padding: 28px 1rem
    }
}

@media screen and (max-width: 768px) {
    .profesor__main .main__content {
        margin-bottom: 5rem;
    }
}

