.alumno__dashboard-portada {
    max-height: 100%;
}

/* Informacion Perfil */

.component__infoPerfil {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    position: absolute;
    height: auto;
    left: 50px;
    bottom: -240px;
}

@media screen and (min-width: 1920px) and (min-height: 1200px) {
    .component__infoPerfil {
        bottom: -320px;
    }
}

.component__infoPerfil {
    position: absolute;
    top: 0;
}

@media screen and (max-width: 1280px) {
    .component__infoPerfil {
        bottom: 0;
        width: 100%;
        left: 0;
        flex-direction: column;
        top: 5%;
    }
}

@media screen and (max-height: 600px) {
    .component__infoPerfil {
        bottom: 0;
        width: 100%;
        left: 5%;
        flex-direction: row;
        top: 5%;
    }
}

@media screen and (max-width: 768px) {
    .component__infoPerfil {
        top: 10%;
    }
}

.component__infoPerfil h2 {
    color: #fff;
    margin-bottom: 6px;
    letter-spacing: 1px;
    text-shadow: rgb(85, 82, 82) 1px 1px 0;
}

@media screen and (min-width: 1028px ) {
    .component__infoPerfil h2 {
        font-size: 28px;
    }
}

.component__infoPerfil span {
    color: #f3f3f3a4;
    font-size: 18px;
}

/* Info perfil */

.component__infoPerfil .info__profile .alias_profile {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.component__infoPerfil .info__profile .alias_profile .show {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.component__infoPerfil .info__profile .alias_profile button {
    background-color: transparent;
}

.component__infoPerfil .info__profile .alias_profile .show .edit_btn {
    cursor: pointer;
    opacity: 0.5;
}

.component__infoPerfil .info__profile .alias_profile .show .edit_btn:hover {
    opacity: 1;
}

.component__infoPerfil .info__profile .alias_profile .show .edit_btn svg {
    color: #fff;
}

.component__infoPerfil .info__profile .alias_profile .edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.component__infoPerfil .info__profile .alias_profile .edit input[type="text"] {
    padding: 6px 6px;
    border-radius: var(--border-radius-input);
    font-weight: 500;
    background-color: transparent;
    border: 1px solid #eee;
    color: #fff;
    font-size: 15px;
}

.component__infoPerfil .info__profile .alias_profile .edit .options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.component__infoPerfil .info__profile .alias_profile .edit .options button {
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    opacity: .5;
    display: grid;
    place-content: center;
}

.component__infoPerfil .info__profile .alias_profile .hidden {
    display: none;
}

/* Foto de perfil */

.component__fotoPerfil {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    padding: 4px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: all .25s ease-in-out;
}

.component__fotoPerfil label {
    cursor: pointer;
    position: relative;
    transition: all .35s ease-in-out;
}

.component__fotoPerfil label .placeholder {
    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;
    z-index: 9999;
    position: absolute;
    top: 0;
    display: none;
}

.component__fotoPerfil label .placeholder svg {
    color: #fff;
    font-size: 34px;
}

.component__fotoPerfil label input[type="file"] {
    display: none;
}

.component__fotoPerfil label:hover .wrapper img {
    filter: brightness(80%);
    transition: filter .35s ease-in;
}

.component__fotoPerfil label .wrapper {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.component__fotoPerfil label .wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width: 1280px) {
    .component__fotoPerfil {
        width: 160px;
        height: 160px;
    }
}

@media screen and (max-width: 768px) {
    .component__fotoPerfil {
        width: 10rem;
        height: 10rem;
    }
}

@media screen and (max-width: 600px) {
    .component__fotoPerfil {
        width: 8rem;
        height: 8rem;
    }
}


@media screen and (max-height: 800px) {
    .component__fotoPerfil {
        width: 9rem;
        height: 9rem;
    }
    .component__infoPerfil {
        bottom: -180px;
    }
}

@media screen and (max-height: 600px) {
    .component__fotoPerfil {
        width: 6rem;
        height: 6rem;
    }
}