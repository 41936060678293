.component__recoveryPassword{
    width: 100%;
    display: grid;
    place-content: center;
    background-color: var(--background-color-secundary);
}

.component__recoveryPassword .form-recovery{
    width: 380px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;    min-height: 450px;
    padding: 2rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media screen and (max-width: 450px) {
    .component__recoveryPassword .form-recovery{
        width: 90vw;
    }
}

.component__recoveryPassword .form-recovery .title .icon{
    font-size: 54px;
    text-align: center;
}

.component__recoveryPassword .form-recovery .title h2{
    text-align: center;
    
}