.component__titleExercise {
    padding-bottom: 16px;
}

.component__titleExercise h2 {
    color: var(--color-primary);
}


.component__titleExercise span {
    font-weight: 500;
    color: var(--color-subtitle);
}