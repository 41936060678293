.alumno__dashboard {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "profile  pubs" 
                         "books    pubs";
    /* max-height: 100%; */
}

@media screen and (max-width: 1024px) {
    .alumno__dashboard {
        grid-template-columns: 3fr 1fr;
    }
}

.alumno__dashboard .wrapper__profile {
    background-color: transparent;
    grid-area: profile;
    position: relative;
    height: 100%;
    max-height: 500px;
}

.alumno__dashboard .wrapper__pubs {
    grid-area: pubs;
    height: 100%;
}

.alumno__dashboard .wrapper__books {
    grid-area: books;
    height: 100%;
    max-width: 100%;
    overflow: auto;
    margin-top: 1.5%;
}
@media (max-width: 768px ) {
    .alumno__dashboard .wrapper__books {
       margin-top: 5%;
    } 
    
}

@media screen and (max-width: 768px) {
    .alumno__dashboard {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr;
        grid-template-areas: "profile" "books";
    }
    .alumno__dashboard .wrapper__profile {
        max-height: inherit;
    }
    .alumno__dashboard .wrapper__pubs {
        display: none;
    }
}