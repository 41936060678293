/* Component Header */

.component__headerProfesor {
    background-color: var(--color-secundary);
    width: 100%;
    position: sticky;
    top: 0;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    padding: 0 2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    height: 68px;
}

@media screen and (max-width: 768px) {
    .component__headerProfesor {
        grid-template-columns: 2fr 1fr;
    }
}

@media screen and (max-width: 480px) {
    .component__headerProfesor {
        grid-template-columns: 6fr 1fr;
    }
}

/* Navegation */

.component__headerProfesor .navegation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.component__headerProfesor .navegation .list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    gap: 50px;
}

.component__headerProfesor .navegation .list-item a {
    display: flex;
    align-items: center;
    height: 100%;
}

.component__headerProfesor .navegation .list-item button.item {
    background-color: transparent;
    color: #fff;
    font-weight: 500;
    opacity: 0.7;
    position: relative;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.component__headerProfesor .navegation .list-item button.item svg {
    font-size: 24px;
    margin-bottom: 6px;
}

.component__headerProfesor .navegation .list-item button.item span {
    color: #fff;
    font-size: 13px;
}

.component__headerProfesor .navegation .list-item .selected button.item {
    opacity: 1;
}

.component__headerProfesor .navegation .list-item .selected button.item::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 130%;
    height: 2px;
    background-color: #fff;
}

.component__headerProfesor .navegation .list-item button.item:hover {
    opacity: 1;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .component__headerProfesor {
        position: initial;
    }
    .component__headerProfesor .navegation {
        position: absolute;
        bottom: 0;
        height: auto;
        background-color: var(--color-primary);
        width: 100%;
        left: 0;
        padding: 8px 0;
        z-index: 999;
    }
    .component__headerProfesor .navegation .list-item .selected button.item::after {
        display: none;
    }
    .component__headerProfesor .navegation .list-item button.item {
        gap: 0;
    }
    .component__headerProfesor .navegation .list-item button.item svg {
        font-size: 18px;
    }
}

@media screen and (max-width: 480px) {
    .component__headerProfesor .navegation {
        position: absolute;
        bottom: 0;
        height: auto;
        background-color: var(--color-primary);
        width: 100%;
        left: 0;
        padding: 10px 0;
    }
    .component__headerProfesor .navegation .list-item button.item svg {
        font-size: 20px;
    }
    .component__headerProfesor .navegation .list-item button.item span {
        font-size: 11px;
    }
}

.component__headerProfesor .book {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.component__headerProfesor .icon {
    width: 90px;
}

.component__headerProfesor .icon img {
    width: 100%;
    height: 100%;
}

/* Select Book */

.component__selectBook .book__information {
    padding: 0 10px;
    width: 100%;
}

.component__selectBook .book__information .selected__button {
    background-color: transparent;
    display: grid;
    grid-template-columns: 36px 1fr;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    padding: 4px 6px;
    padding-right: 12px;
    transition: all .30s ease-in-out;
}

.component__selectBook .book__information .selected__button:hover {
    background-color: var(--color-primary-hover);
    cursor: pointer;
}

.component__selectBook .book__information .selected__button:hover .title {
    opacity: 1;
    transition: all .30s ease-in-out;
}

.component__selectBook .book__information .selected__button .img-book {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
}

.component__selectBook .book__information .selected__button .img-book img {
    width: 100%;
}

.component__selectBook .book__information .selected__button .title {
    display: inline-block;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    opacity: 0.7;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal__selectBook-overlay {
    background-color: rgba(0, 0, 0, 0.3);
}


/* ModalSelectBook */

.component__modalSelectBook{
    background-color: #fff;
    width: 720px;
    padding: 38px 48px;
    max-height: 90vh;
    border-radius: var(--border-radius-input);
    overflow: auto;
    height: 100%;
}

.component__modalSelectBook .content{
    position: relative;
    overflow: auto;
    height: 100%;
}

.component__modalSelectBook .title {
    display: grid;
    place-content: center;
}

.component__modalSelectBook .title h2{
    text-align: center;
    padding-bottom: 10px;
    position: relative;
    display: inline-block;
}

.component__modalSelectBook .title h2::after {
    content: '';
    width: 35px;
    position: absolute;
    left: 40%;
    right: 50%;
    bottom: 0;
    border-bottom: 2px solid var(--color-primary);
}

.component__modalSelectBook .content .books-items{
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    padding: 18px 0;
    overflow: auto;
}

@media screen and (max-width: 768px) {
    .component__modalSelectBook {
        width: 90vw;
    }

    .component__modalSelectBook .content .books-items {
        max-height: 70vh;
    }
}

.component__modalSelectBook .content .books-items .book {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 16px 24px;
    border-radius: var(--border-radius-input);
    transition: all .30s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
}

.component__modalSelectBook .content .books-items .book p{
    text-align: center;
    font-weight: 600;
}

.component__modalSelectBook .content .books-items .book div{
    text-align: center;
}

.component__modalSelectBook .content .books-items .book div span{
    display: inline-block;
    text-align: center;
    font-weight: 600;
    opacity: 0.7;
    position: relative;
    font-size: 13px;
}

.component__modalSelectBook .content .books-items .book div span:first-child::after{
   content: '•';
    margin: 0 4px;
}

.component__modalSelectBook .content .books-items .book.selected {
    background-color: var(--color-primary);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.component__modalSelectBook .content .books-items .book.selected:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.component__modalSelectBook .content .books-items .book:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    cursor: pointer;
}

.component__modalSelectBook .content .books-items .book img{
    width: 100%;
}

.component__modalSelectBook .content .books-items .book.selected p,
.component__modalSelectBook .content .books-items .book.selected span{
    color: #fff;
}

/*  My Profile */

.profile {
    display: flex;
    justify-content: flex-end;
    width: auto;
}

/* ------------------------------------------------------------------- */

/* Component Mi Perfil  */

.component__miPerfilProfesor button.profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 4px 6px;
    background-color: transparent;
    transition: background-color .20s ease-in-out;
    border-radius: 20px;
}

.component__miPerfilProfesor button.profile:hover {
    background-color: var(--color-primary-hover);
    cursor: pointer;
}

.component__miPerfilProfesor button.profile .avatar-img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    overflow: hidden;
    display: grid;
    place-content: center;
}

.component__miPerfilProfesor button.profile .avatar-img>img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.component__miPerfilProfesor button.profile .name {
    color: #fff;
    font-weight: 600;
}

@media screen and (max-width: 480px) {
    .component__miPerfilProfesor button.profile .name {
        display: none;
    }
}

/* Modal My Profile Float */

.float-modal-content {
    background-color: #fff;
    padding: 20px;
    width: 270px;
    border-radius: var(--border-radius-input);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.float-modal-arrow {
    display: none;
}

.content_ModalFloat {
    background-color: #fff;
}

.content_ModalFloat .items-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.content_ModalFloat .items-list button {
    background-color: transparent;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-weight: 600;
    padding: 6px 6px;
    border-radius: var(--border-radius-input);
    transition: background-color .15s ease-in-out;
}

.content_ModalFloat .items-list button svg {
    font-size: 30px;
    color: var(--color-text);
    background-color: #eee;
    border-radius: 50%;
    padding: 4px;
}

.content_ModalFloat .items-list button:hover {
    background-color: rgba(242, 242, 242, 0.979);
    cursor: pointer;
}

.content_ModalFloat .version {
    padding: 4px 0;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text-opacity);
}

@media screen and (max-width: 480px) {
    .float-modal-content {
        background-color: #fff;
        padding: 12px 16px;
        width: auto;
    }
    .content_ModalFloat .items-list {
        gap: 1px;
    }
}

/* Información basica */

.change__avatar {
    width: 100%;
}

.change__avatar .button-input-file {
    margin-bottom: 2px;
}

.change__avatar .button-input-file>button {
    margin-left: 10px;
}

.change__avatar .button-input-file input[type="file"] {
    display: none;
}

.change__avatar {
    display: grid;
    grid-template-columns: 1fr 3fr;
    justify-content: center;
    align-items: center;
}

.change__avatar .image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    display: grid;
    place-content: center;
    position: relative;
    display: inline-block;
}

.change__avatar .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.change__avatar .options button {
    margin-bottom: 8px;
}

.change__avatar .options .text span {
    color: var(--color-subtitle);
    font-size: 11px;
    display: block;
}

.info-basic .form-basic {
    padding: 16px 0;
}

@media screen and (max-width: 480px) {
    .change__avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
    .change__avatar .image {
        background-color: red;
    }
    .change__avatar .options {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

