.component__testing {
    background-color: var(--background-color-student);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 68px 1fr;
}

.component__testing .header {
    background-color: var(--color-primary);
    padding: 12px 20px;
    display: grid;
    place-content: center;
}

.component__testing .header h2 {
    color: #fff;
}

.component__contentEjercicio {
    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;
    background: #f4f4f4;
}

.component__contentEjercicio .content-exercise {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding: 16px;
}

@media (min-width: 1024px ) { 
    .component__contentEjercicio .content-exercise {
        min-width: 100vh;
    }
}

.c__showCalificacion .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
}