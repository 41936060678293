button.component__button {
    font-size: 14px;
    padding: 10px 14px;
    border-radius: var(--border-radius-input);
    cursor: pointer;
    color: #fff;
    width: auto;
    transition: background-color .25s ease-in-out;
    font-weight: 500;
    position: relative;
}

button.component__buttonText{
    font-size: 14px;
    padding: 2px 4px;
    font-weight: 500;
    position: relative;
    background-color: transparent;
    cursor: pointer;
}

button.component__buttonText:hover {
    text-decoration: underline;
}

.component__button.primary {
    background-color: var(--color-primary);
}

.component__buttonText.primary {
    color: var(--color-primary);
}

.component__buttonText.primary:hover {
    color: var(--color-primary-hover);
}

.component__button.primary:hover {
    background-color: var(--color-primary-hover);
}

.component__button.success {
    background-color: #38b000;
}

.component__button.success {
    background-color: #38b000;
}

.component__button.success:hover {
    background-color: #277A00;
}

.component__button.cancel {
    background-color: var(--color-btn-delete);
}

.component__button.cancel:hover {
    background-color: var(--color-btn-delete-hover);
}

.component__button::after {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    position: absolute;
    left: calc(50% - 0.75em);
    top: calc(50% - 0.75em);
    border: 0.15em solid transparent;
    border-right-color: white;
    border-radius: 50%;
    animation: button-anim 0.7s linear infinite;
    opacity: 0;
}

.component__buttonText::after {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    position: absolute;
    left: calc(50% - 0.75em);
    top: calc(50% - 0.75em);
    border: 0.15em solid transparent;
    border-right-color: var(--color-primary);
    border-radius: 50%;
    animation: button-anim 0.7s linear infinite;
    opacity: 0;
}


@keyframes button-anim {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.component__button.loading {
    color: transparent;
}


.component__button.loading::after {
    opacity: 1;
}

.component__buttonText.loading,
.component__buttonText.loading:hover {
    color: transparent;
}

.component__buttonText.loading::after {
    opacity: 1;
}