.component__publicaciones {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 10px;
    padding: 12px 18px;
}

.component__publicaciones svg{
    font-size: 38px;
    margin: 8px 0;
    color: var(--color-text);
}