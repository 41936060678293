.component_Dashboard .wrapper{
    padding: 5% 0;
}

.component_Dashboard .wrapper .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.component_Dashboard .wrapper .title h2 {
    font-size: 1.23rem;
}


.component_Dashboard .wrapper .title button{
    background-color: transparent;
    color: var(--color-primary);
    cursor: pointer;
    padding: 8px 20px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    transition: background-color .20s ease-in-out;
    
}

.component_Dashboard .wrapper .title button:hover{
    background-color: rgba(230, 230, 230, 0.801);
}