.component__headerProfile {}

.component__headerProfile .profile__wrapper .btnProfile {
    padding: 2px 10px 2px 4px;
    border-radius: 20px;
    background-color: transparent;
    transition: background-color .30s ease-in-out;
}

.component__headerProfile .profile__wrapper .btnProfile:hover {
    background-color: var(--color-secundary);
    cursor: pointer;
}

.component__headerProfile .profile__wrapper .btnProfile .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.component__headerProfile .profile__wrapper .btnProfile .content .foto-profile {
    width: 32px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    display: grid;
    place-content: center;
}

.component__headerProfile .profile__wrapper .btnProfile .content .foto-profile img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.component__headerProfile .profile__wrapper .btnProfile .content .icon-profile {
    display: none;
    color: #eee;
    font-size: 18px;
    place-content: center;
}

.component__headerProfile .profile__wrapper .btnProfile .content span {
    color: #fff;
    font-weight: 700;
    display: inline-block;
    font-size: 14px;
    text-transform: capitalize;
}

@media screen and (max-width: 768px) {
    
    .component__headerProfile .profile__wrapper .btnProfile .content .icon-profile {
        display: grid;
    }
}

/* Componente Menu */

.component__menuProfile {
    background-color: #fff;
    padding: 10px 6px;
    border-radius: 5px;
    min-width: 200px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.component__menuProfile .item__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.component__menuProfile .item__list .item button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 8px;
    border-radius: 4px;
    background-color: transparent;
    transition: all .30s ease-in-out;
}

.component__menuProfile .item__list .item button:hover {
    background-color: #eee;
    cursor: pointer;
}

.component__menuProfile .item__list .item button svg {
    font-size: 18px;
    color: var(--color-text);
}

.component__menuProfile .item__list .item button span {
    font-size: 15px;
    font-weight: 600;
}


@media screen and (max-width: 768px) {
    .component__menuProfile {
        min-width: inherit;
    }
    .component__menuProfile .item__list .item button {
        font-size: 15px;
        font-weight: 600;
        white-space: nowrap;
        text-align: left;
    }

    .component__menuProfile .item__list .item button span {
        font-size: 13px;
    }

    .component__menuProfile .item__list .item button svg {
        font-size: 16px;
    }
}