.component__mostrarCalificacion {
    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;
}

.component__mostrarCalificacion .content {
    
}

.component__mostrarCalificacion .content .lottie{
    width: 220px;
}

.component__mostrarCalificacion .content .text {
    text-align: center;
}

.component__mostrarCalificacion .content .text h3 {
    color: var(--color-primary);
    font-size: 2rem;
    margin-bottom: 12px;
}