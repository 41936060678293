.component__showError{
    background-color: #F8D7DA;
    padding: 12px 14px;
    border: 1px solid #F5C6CB;
    border-radius: 3px;
    max-width: 100%;
}

.component__showError > .content-error{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #721C24;
    gap: 10px;
    height: auto;
    padding: 0;
}

.component__showError > .content-error .icon{
    font-size: 18px;
}

.component__showError > .content-error .message{
    font-size: 14px;
    font-weight: 500;
}