.selectLibro .content .wrapper__books{
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    gap: 16px;
    overflow: auto;
    padding: 16px;
}

/* Libro */

.component__selectLibro{
    max-width: 220px;
    min-width: 220px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    padding: 14px 16px;
    transition: all .35s ease-in-out;
}

.component__selectLibro.disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.component__selectLibro:hover {
    transform: translateY(-4px);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.component__selectLibro.disabled:hover {
    transform: inherit;
    box-shadow: inherit;
}

.component__selectLibro .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    height: 100%;
}

.component__selectLibro .content .portada-img img {
    width: 100%;
}

.component__selectLibro.disabled .content .portada-img img {
    filter: grayscale(100%);
}

.component__selectLibro .content .title {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.component__selectLibro .content .title h2{
    font-size: 16px;
    text-align: center;
}

.component__selectLibro .content .title .autor {
    font-weight: 600;
    opacity: 0.7;
    font-size: 13px;
}

.component__selectLibro .content button{
    background-color: var(--color-primary);
    border-radius: var(--border-radius-input);
    color: #fff;
    padding: 6px 10px;
    font-size: 14px;
    transition: all .20s ease-in-out;
}

.component__selectLibro .content button:hover{
    background-color: var(--color-secundary);
    cursor: pointer;
}

.component__selectLibro.disabled .content span{
    font-size: 14px;
    display: inline-block;
    font-weight: 600;
    text-align: center;
}