.component__ejercicios{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 80px auto;
    gap: 18px;
    position: relative;
    /* overflow: hidden; */
}

.component__ejercicios .buttons__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.component__ejercicios .buttons__wrapper button {
    color: var(--color-text-opacity);
    font-weight: 500;
    background-color: transparent;
    padding: 8px 12px;
    border-radius: 5px;
    transition: all .20s ease-in-out;
}

.component__ejercicios .buttons__wrapper button:hover{
    background-color: var(--color-text-opacity);
    color: #eee;
    cursor: pointer;
}

.component__ejercicios .header{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.component__ejercicios .header .select{
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.component__ejercicios .header .select span {
    font-size: 13px;
    font-weight: 600;
}


/* Component MensajeBombaTiempo */
.component__mensajeBombaTiempo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.component__mensajeBombaTiempo .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.component__mensajeBombaTiempo .content svg {
    color: var(--color-primary);
    font-size: 48px;
}

.component__mensajeBombaTiempo .content h2{
    color: var(--color-primary);
}

.component__mensajeBombaTiempo .content p{
    font-weight: 500;
    opacity: 0.8;
}


/* Component EjerciciosTest */
