.component__portadaAlumno{
    width: 98%;
    height: 95%;
    position: absolute;
    border-radius: 20px;
    overflow: hidden;
}


.component__portadaAlumno img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.component__portadaAlumno .component__cambiarPortada{
    position: absolute;
    z-index: 999;
    bottom: 35px;
    right: 50px;
}

.component__portadaAlumno .component__cambiarPortada .btn__change{
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 4px;
}

.component__portadaAlumno .component__cambiarPortada .btn__change:hover{
    cursor: pointer;
    background-color: rgb(240, 240, 240);
}

.component__portadaAlumno .component__cambiarPortada .btn__change svg {
    font-size: 20px
}
.component__portadaAlumno .component__cambiarPortada .btn__change span {
    font-weight: 600;
    font-size: 14px
}

.component__portadaAlumno .component__cambiarPortada .btn__change input[type="file"] {
    display: none;
}
