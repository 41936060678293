.content__info-text {
	margin-bottom: 15px;
	font-weight: bold;
    text-transform: uppercase;
}

.content__info-text p {
	font-weight: normal;
    text-transform: none;
    text-align: justify;

}
