.component__alumnos{
    /* background-color: yellow; */
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    
}

.component__alumnos .content_alumnos{
    height: 100%;
    width: 100%;
   
}


.component__alumnos .content_alumnos  {
    display: grid;
    grid-template-columns: minmax(170px, 320px) 4fr;
    gap: 2rem;
}

.component__alumnos .content_alumnos .listAlumnos .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.component__alumnos .content_alumnos .listAlumnos .title .select__option select {
    padding: 8px 6px;
    border: 1px solid #eee;
    border-radius: var(--border-radius-input);
    cursor: pointer;
}

.component__alumnos .content_alumnos .listAlumnos .title .select__option select:focus{
    border: 1px solid var(--color-primary);
}

@media screen and (max-width: 768px) {
    .component__alumnos .content_alumnos  {
        display: grid;
       
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .component__alumnos .content_alumnos div.indice{
        position: absolute;
        width: 50%;
        left: -100%;
        background-color: #fff;
    }
}
