.component_Grupos {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
	gap: 10px;
}
.component_Grupos .alice-carousel .alice-carousel__stage {
	z-index: 1;
}

.component_Grupos .alice-carousel .alice-carousel__stage-item {
	padding: 20px 0;
	margin-bottom: 12px;
}

.component_Grupos .alice-carousel .alice-carousel__stage-item:not(.__cloned) {
	width: auto !important;
	margin-right: 1rem;
}

/* Grupo Item */

.component__grupoItem {
	background-color: #fff;
	width: 100%;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
		rgba(0, 0, 0, 0.05) 0px 5px 10px;
	min-height: 132px;
	width: 300px;
	border-radius: 5px;
	padding: 14px 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0;
	cursor: pointer;
}

.component__grupoItem .header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.component__grupoItem .header .icon {
	background-color: var(--color-primary);
	color: #fff;
	padding: 6px 10px;
	border-radius: 10px;
	font-size: 24px;
}

.component__grupoItem .header .icon.false {
	background-color: rgb(177, 174, 174);
	color: rgb(129, 124, 124);
}

.component__grupoItem .header .options button {
	background-color: transparent;
	font-size: 20px;
	display: flex;
	align-items: center;
	padding: 4px;
	border-radius: 50%;
	transition: background-color 0.2s ease-in-out;
}

.component__grupoItem .header .options button:hover {
	cursor: pointer;
	background-color: #eee;
}

.component__grupoItem .info {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
	width: 100%;
}

.component__grupoItem .info .name {
	font-weight: 600;
	font-size: 16px;
}

.component__grupoItem .info .size_group {
	font-size: 13px;
	font-weight: 400;
	color: var(--color-subtitle);
}

.option-modal-content {
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
	padding: 10px;
	border-radius: 5px;
}

.option-modal-content .component_options .list {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.option-modal-content .component_options .list .item button {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 6px;
	width: 100%;
	padding: 4px 6px;
	border-radius: var(--border-radius-input);
	background-color: transparent;
}

.option-modal-content .component_options .list .item button:hover {
	background-color: rgba(238, 238, 238, 0.835);
	cursor: pointer;
}

.option-modal-content .component_options .list .item button .text {
	text-align: left;
}

.option-modal-content .component_options .list .item button .text span {
	font-size: 14px;
	font-weight: 600;
}

.option-modal-content .component_options .list .item button .text div {
	font-size: 12px;
	margin-top: 3px;
	color: var(--color-subtitle);
}

/* Styles No Found Data  */

.NoFoundData {
	width: 100%;
	height: 120px;
	display: grid;
	place-content: center;
}

.NoFoundData .show_message .icon {
	display: grid;
	place-content: center;
	padding: 8px 0;
}

.NoFoundData .show_message .icon img {
	width: 54px;
}

.NoFoundData .show_message .text {
	color: var(--color-subtitle);
	font-size: 14px;
}

.control__checked {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
}

.control__checked span {
	color: var(--color-text-opacity);
	font-size: 12px;
}

/* TemaEnProceso */

.component__temaEnProceso .content > div {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.component__temaEnProceso .content > div span {
	font-weight: 500;
	font-size: 14px;
}

.component__temaEnProceso .wrapper-button {
	padding: 12px 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.component__temaEnProceso .wrapper-process {
}
