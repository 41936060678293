.component__quiz {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.component__quiz .wrapper__questions .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.component__quiz .title h2 {
    font-size: 1.5rem;
    color: var(--color-primary);
}

.component__quiz .title p {
    font-weight: 500;
    color: var(--color-subtitle);
}

.component__quiz .title .img {
    max-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.component__quiz .title .img img {
    width: 100%;
}

.component__quiz .wrapper__questions .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.component__quiz .wrapper__questions .content .size-questions span {
    font-weight: 500;
}

.component__quiz .wrapper__questions .content .title {
    margin: 8px 0;
}

.component__quiz .wrapper__questions .content .title h3 {
    margin: 6px 0;
    font-size: 1.2rem;
    max-width: 520px;
    text-align: center;
}

.component__quiz .wrapper__questions .content .questions {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 12px 0;
    max-height: 350px;  
    overflow-y: auto;
}

/* Scroll */
.component__quiz .wrapper__questions .content .questions::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
.component__quiz .wrapper__questions .content .questions::-webkit-scrollbar-track {
	background-color: #ececec;
	border-radius: 10px;
}
.component__quiz .wrapper__questions .content .questions::-webkit-scrollbar-thumb {
	background-color: #c4c4c4;
	border-radius: 10px;
}
/* --- */

.component__quiz .wrapper__questions .content .buttons button{ 
    font-size: 15px;
}

.component__quiz .wrapper__questions .content .buttons .send__data {
    background-color: var(--color-btn-success);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.component__quiz .wrapper__questions .content .buttons .send__data * {
    color: #fff;
}

/* QuestionBox Component*/

.component__questionBox .size-questions{
    text-align: center;
}

.component__questionBox .size-questions span{
    font-weight: 700 !important;
    color: var(--color-text-opacity);
}

.component__questionBox .size-questions span:first-child {
    font-size: 18px;
}

.component__questionBox .size-questions span:last-child {
    font-size: 14px;
}

.component__questionBox  .title .img {
    max-width: 300px;
    min-width: 100px;
}

.component__questionBox  .size-questions {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.component__questionBox  .title .img img{
    width: 100%;
}   

/* AnswerBox Component */

.component__answerBox {
    /* width: 340px; */
    display: flex;
    align-items: center;
    border-radius: 10px;
    transition: all .30s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.component__answerBox:hover {
    
}

.component__answerBox.selected {
    background-color: #ff6d00;
    box-shadow: none;
}

.component__answerBox .btn__selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 14px;
    background-color: transparent;
    cursor: pointer;
    padding: 18px 16px;
}

.component__answerBox .btn__selected:active{
    background-color: transparent;
}


.component__answerBox .btn__selected .number {
    border-radius: 50%;
    background-color: #ff8500;
    width: 24px;
    height: 24px;
    display: grid;
    place-content: center;
}


.component__answerBox.selected .number{
    background-color: #fff;
}

.component__answerBox .btn__selected .number span {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}

.component__answerBox.selected .btn__selected .number span {
    color: #ff8500;
}

.component__answerBox .text {
    text-align: left;
    font-size: 14px;
}

.component__answerBox .text span {
    color: var(--color-subtitle);
    font-weight: 500;
}

.component__answerBox .text.with-img {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    min-height: 80px;
}

.component__answerBox .text.with-img .img {
    width: 150px;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.component__answerBox .text.with-img .img img {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
}

.component__answerBox.selected .text span {
    color: #fff;
}