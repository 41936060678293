.component__home {
	width: 100%;
	height: 100%;
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	background-color: #fcfbfc;
}

.component__home .header {
	background-color: var(--color-primary);
	padding: 0rem 10% 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
}
@media (min-width: 102px) {
	.component__home .header {
		gap: 20px;
	}
}

.component__home .header .navegation .logo {
	max-width: 160px;
}

.component__home .header .navegation .logo img {
	width: 100%;
}

.component__home .header .svg_figure {
	display: flex;
	flex-direction: row;
	position: relative;
	flex-wrap: wrap;
	width: 60%;
}

.component__home .header .svg_figure .text h2 {
	font-size: 2rem;
	color: #fff;
	line-height: 1.4;
}

.component__home .header .svg_figure .svg {
	position: relative;
}

.component__home .header .svg_figure .svg .svg-1 {
	width: 200px;
}

.component__home .header .svg_figure .svg .svg-2 {
	width: 120px;
	position: relative;
	left: 20px;
}

.component__home .header .svg_figure .svg .svg-3 {
	width: 200px;
	position: relative;
	top: -150px;
	right: 0;
	transform: rotate(1.2);
}

/* FormLogin */
.component_FormLogin {
	background-color: #fff;
	position: absolute;
	max-width: 480px;
	right: 12%;
	top: 25%;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	border-radius: var(--border-radius-input);
	padding: 2rem;
}

@media screen and (max-width: 1290px) {
	.component__home .header {
		padding: 0rem 5% 0;
		justify-content: center;
	}

	.component_FormLogin {
		right: 4rem;
	}

	.component__home .header .svg_figure .svg .svg-1 {
		width: 120px;
	}

	.component__home .header .svg_figure .svg .svg-2 {
		width: 100px;
		left: 20px;
	}

	.component__home .header .svg_figure .svg .svg-3 {
		width: 120px;
		position: relative;
		top: -90px;
		right: 0;
	}
}
@media screen and (max-width: 1000px) {
	.component__home .header .svg_figure img {
		display: none;
	}

	.component__home .header {
		background-color: var(--color-primary);
		padding: 2rem 1rem;
		justify-content: flex-start;
		align-items: center;
	}

	.component__home .header .svg_figure {
		width: 100%;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	.component_FormLogin {
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: 80vw;
		top: 30%;
	}
}

@media screen and (max-height: 800px) {
	.component_FormLogin {
		/* top: 20%; */
	}
}

@media screen and (max-width: 648px) {
	.component_FormLogin {
		top: 35%;
	}
}

@media screen and (max-height: 480px) {
	.component_FormLogin {
		top: 16%;
	}
}

.component_FormLogin .title {
	margin-bottom: 28px;
}

.component_FormLogin .title h2 {
	margin-bottom: 10px;
}

.component_FormLogin .title p {
	color: #5d5d5f;
	font-weight: 500;
}

.component_FormLogin .content .form-login {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1.5rem;
}

.component_FormLogin .content .form-login .input {
	width: 100%;
	font-size: 13px;
}

.component_FormLogin .content .form-login input {
	background-color: #f1f2f6;
	padding: 16px 12px;
	border-radius: var(--border-radius-input);
	font-weight: 500;
	width: 100%;
	font-size: 16px;
}

.component_FormLogin .content .form-login .input .msg-error {
	font-size: 13px;
	margin-top: 6px;
	display: inline-block;
	color: red;
}

.component_FormLogin .content .form-login > button {
	background-color: var(--color-btn-primary);
	padding: 16px 12px;
	color: #fff;
	font-weight: 600;
	border-radius: var(--border-radius-input);
	transition: background-color 0.2s ease-in-out;
	font-size: 14px;
}

.component_FormLogin .content .form-login > button:hover {
	background-color: var(--color-btn-primary-hover);
	cursor: pointer;
}

.component_FormLogin .content .form-login .help {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.component_FormLogin .content .form-login .help > button {
	background-color: transparent;
	color: var(--color-btn-primary-hover);
}

.component_FormLogin .content .form-login .help > button:hover {
	background-color: transparent;
	cursor: pointer;
	text-decoration: underline;
}

.component_FormLogin .content .form-login .error_login {
	padding: 16px 10px;
	background-color: #f8d7da;
}

.component_FormLogin .content .form-login .error_login p {
	color: #9d1c24;
	font-weight: 500;
	font-size: 15px;
	display: flex;
	align-items: center;
	gap: 6px;
}
