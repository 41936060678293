.component__viewerPDF {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    max-width: 90vw;
    max-height: 90vh;

    -youbkit-touch-callout: none; 
    -youbkit-user-select: none;   
    -moz-user-select: none;    
    -ms-user-select: none;       
    user-select: none;            
}


.component__viewerPDF .header{
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px 12px;
    gap: 8px;
}

.component__viewerPDF .header button{
    background-color: transparent;
    color: #fff;
    display: grid;
    place-content: center;
    font-size: 18px;
}

.component__viewerPDF .header button:hover{
    cursor: pointer;
}

.component__viewerPDF .header span{
    color: #fff;
    font-weight: 500;
}

.component__viewerPDF .content {
    width: 100%;
    overflow: auto;
}

.component__viewerPDF .content .rpv-default-layout-container{
    border: 0;
}

@media screen and (max-width: 648px){
    .component__viewerPDF {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        max-width: 100vw;
        max-height: 100vh;
    }
}

/* .component__viewerPDF{
    height: 95vh;
    width: 980px;
}

@media screen and (min-width: 1440px) {
    .component__viewerPDF{
        width: 1200px;
    }
}

@media screen and (min-width: 1200px) {
    .component__viewerPDF{
        width: 900px;
    }
}

@media screen and (max-width: 1000px) {
    .component__viewerPDF{
        width: 90vw;
    }
}

@media screen and (max-width: 768px) {
    .component__viewerPDF{
        height: 80vh;
        width: 90vw;
    }
}

@media screen and (max-width: 648px) {
    .component__viewerPDF{
        height: 100vh;
        width: 100vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 50px 1fr;
    }

    .component__viewerPDF{
        height: 100vh;
        width: 100vw;
    }

    .component__viewerPDF .content {
        width: vw;
        
    }
} */