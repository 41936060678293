.wrapper_memorama {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 1.4rem 0 !important;
}

.wrapper_memorama .wrapper_button button {
	background-color: var(--color-primary);
	padding: 6px 12px;
	color: #fff;
	border-radius: var(--border-radius-input);
	font-weight: 500;
	transition: all 0.25s ease-out;
	font-size: 14px;
	display: flex;
	flex-direction: row;
	gap: 4px;
}

.wrapper_memorama .wrapper_button button svg {
	font-size: 18px;
}

.wrapper_memorama .wrapper_button button:hover {
	cursor: pointer;
	background-color: var(--color-primary-hover);
}

.wrapper-animation {
	position: absolute;
	z-index: 3;
}

.wrapper_memorama .title {
	font-weight: 500;
	font-size: 1.4rem;
	opacity: 0.7;
	margin-bottom: 12px;
}

.cards {
	width: 100%;
	display: grid;
	grid-gap: 12px;
	place-items: center;
	grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 768px) {
	.cards {
		grid-template-columns: repeat(4, 1fr);
	}
}
@media (min-width: 1024px) {
	.cards {
		grid-template-columns: repeat(5, 1fr);
	}
}

.pokemon-card {
	height: 100px;
	width: 150px;
	border-radius: 10px;
	background: #ff6d00 !important;
	cursor: pointer;
	background: none;
	border: none;
	outline: none;
}

.pokemon-card.with-img {
	height: 150px;
	width: 200px;
}

.pokemon-card.with-img .inner .front .img {
	overflow: hidden;
	padding: 8px;
}

.pokemon-card.with-img .inner .front .img img {
	width: 100%; /* image box size as % of container, see step 1 */
	height: 100%; /* image box size as % of container, see step 1 */
	object-fit: cover; /* matching of image pixels to image box, see step 2 */
}

.pokemon-card.flipped .inner {
	transform: rotateY(180deg);
}

.pokemon-card .inner .front {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.inner {
	position: relative;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: 0.6s cubic-bezier(0.38, 0.02, 0.09, 1.66) all;
}

.pokemon-card .front,
.pokemon-card .back {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	backface-visibility: hidden;
}

.pokemon-card .front {
	background: #ff6d00;
	transform: rotateY(180deg);
}

.pokemon-card .back {
	background: var(--color-text-orange);
	color: var(--color-text-orange);
	font-size: 100px;
	transition: 0.3s ease all;
}

.pokemon-card .back:hover {
	background: #ff8500;
	color: #fff;
	transform: scale(1.05);
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
		rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

/* .pokemon-card.flipped .front,
.pokemon-card.flipped{
  background-color: #2b9348;
}
 */

.front h1 {
  text-align: center;
  width: 100%;
  white-space: break-spaces;
  padding: 5px;
  font-size: 18px;
  color: #fff;
  word-break: break-all;
}

@media screen and (max-width: 768px) {
  .pokemon-card {
    max-width: 130px;
  }
  .front h1 {
    font-size: 1.8rem;
    word-break: break-all;

  }
}

/* @media screen and (max-width: 480px) {
  .cards {
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  }
  .pokemon-card {
    max-width: 120px;
  }
  .front h1 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 414px) {
  .cards {
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  }
  .pokemon-card {
    width: 90px;
  }
  .front h1 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 380px) {
  .cards {
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  }
  .pokemon-card {
    width: 280px;
  }
  .front h1 {
    font-size: 1rem;
  }
} */

/* Timer */

.timer-wrapper {
	display: flex;
	justify-content: center;
	background-color: red;
}

.timer {
	font-size: 0.8rem;
	font-weight: 800;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.text {
	color: #aaa;
}

.value {
	font-size: 40px;
}

.info {
	max-width: 360px;
	margin: 40px auto 0;
	text-align: center;
	font-size: 16px;
}
