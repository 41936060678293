.component__loginProfesor {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

@media screen and (max-width: 768px) {
    .component__loginProfesor {
        grid-template-columns: 1fr;
    }
}

.component__loginProfesor .wrapper_presentation {
    position: relative;
    background: rgb(2, 0, 36);
    background: linear-gradient(347deg, rgba(2, 0, 36, 1) 0%, rgba(0, 31, 84, 1) 0%, rgba(0, 212, 255, 1) 100%);
}

.component__loginProfesor .wrapper_form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 8rem;
}

@media screen and (max-height: 768px) {
    .component__loginProfesor .wrapper_form {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

.component__loginProfesor .wrapper_form>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}


.component__loginProfesor .wrapper_form > div .success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
}


.component__loginProfesor .wrapper_form > div .success .svg {
    width: 68px;
    margin-bottom: 12px;
}

.component__loginProfesor .wrapper_form > div .success .svg img{
    width: 100%;
}

.component__loginProfesor .wrapper_form > div .success .text{
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.component__loginProfesor .wrapper_form > div .success .text h2{
    color: var(--color-btn-success);
}

.component__loginProfesor .wrapper_form > div .success .text p {
    font-weight: 500;
}


.component__loginProfesor .wrapper_form > div .title {
    text-align: left;
    width: 100%;
    padding: 0 10px;
}

.component__loginProfesor .wrapper_form .title h2 {
    font-weight: 700;
    font-size: 1.877rem;
    margin-bottom: 10px;
}

.component__loginProfesor .wrapper_form .title p {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-opacity);
}

.component__loginProfesor .wrapper_form .title p button{
    background-color: transparent;
    margin-left: 4px;
    color: var(--color-primary);
    font-weight: 500;
    cursor: pointer;
}

.component__loginProfesor .wrapper_form .form {
    width: calc(100vw / 3);
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

@media screen and (max-width: 1024px) {
    .component__loginProfesor .wrapper_form .form {
        width: calc(100vw / 2);
    }
}

@media screen and (max-width: 580px){
    .component__loginProfesor .wrapper_form .form {
        width: 90vw;
    }
}

/* Component vPresentation */

.component__vPresentation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
}

.component__vPresentation .icon {
    z-index: 10;
    padding: 12px 18px;
}
.component__vPresentation .icon img{
    max-width: 180px;
}

.component__vPresentation .background {
    background-image: url(http://www.banques-en-ligne.fr/img/calculatrice-credit/background.svg);
    background-repeat: repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    transition: 0.25s ease all;
    opacity: 0.09;
    background-size: 314px;
    background-position-y: -15px;
    background-position-x: -15px;
}

.component__vPresentation .portada-img {
    z-index: 10;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    gap: 28px;
}

.component__vPresentation .portada-img .text h2{
    color: #fff;
    text-align: center;
}


.component__vPresentation .portada-img img{
    width: 60%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: all .25s ease-in-out;
}



@media screen and (max-width: 768px) {
    .component__vPresentation {
        flex-direction: row;
        align-items: center;
    }
    .component__vPresentation .portada-img{
        gap: 12px;
    }
    .component__vPresentation .portada-img img {
       width: 100px;
    }
    .component__vPresentation .portada-img .text h2{
        font-size: 0.99rem;
    }
    .component__vPresentation .portada-img {
        padding: 18px 12px;
    }
}



.component__vPresentation:hover .portada-img img {
    transform: scale(1.1);  
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}


/***************************/