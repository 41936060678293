.component__hangMan {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}


.component__hangMan .figure-words {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-end;
}


.figure-container {
  fill: transparent;
  stroke: var(--color-text);
  stroke-width: 4px;
  stroke-linecap: round;
}

.figure-part {
  display: none;
}

/* Component WrongLetters */
.component__wrongLetters {
  position: absolute;
  top: 48px;
  right: 20px;
  display: flex;
  flex-direction: column;
  text-align: right;
}


.component__wrongLetters p {
  margin: 0 0 5px;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-text-opacity);
}

.component__wrongLetters span {
  font-weight: 500;
  padding: 0.1em 0.6em;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  color: #333;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px #fff inset;
  border-radius: 3px;
  display: inline-block;
  margin: 0px 0.1em;
  text-shadow: 0px 1px 0px #fff;
  line-height: 1.4;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
}

/* Component Word */
.component__word {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}

.component__word span.good {
  display: inline-block;
  background-color: var(--color-btn-success);
  margin-left: 12px;
  color: #fff;
  padding: 4px 6px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
}

.component__word span.bad {
  display: inline-block;
  background-color: var(--color-btn-delete);
  margin-left: 12px;
  color: #fff;
  padding: 4px 6px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
}

.component__word .letter {
  border-bottom: 3px solid var(--color-primary);
  display: inline-flex;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  height: 50px;
  width: 20px;
}

.popup-container {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: center;
}

.popup {
  background: #2980b9;
  border-radius: 5px;
  box-shadow: 0 15px 10px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.popup button {
  cursor: pointer;
  background-color: #fff;
  color: #2980b9;
  border: 0;
  margin-top: 20px;
  padding: 12px 20px;
  font-size: 16px;
}

.popup button:active {
  transform: scale(0.98);
}

.popup button:focus {
  outline: 0;
}

.notification-container {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px 10px 0 0;
  padding: 15px 20px;
  position: absolute;
  bottom: -50px;
  transition: transform 0.3s ease-in-out;
}

.notification-container p {
  margin: 0;
}

.notification-container.show {
  transform: translateY(-50px);
}

/* Component Question */

.component__questionHangMan{
  text-align: center;
  margin-bottom: 16px;
}

.component__questionHangMan p {
  font-size: 16px;
  font-weight: 700;
  color: var(--color-primary);
}


.component__questionHangMan p span {
  font-weight: 500;
  color: var(--color-primary);
}

/* Component FinishWord */
.FinishWord{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
