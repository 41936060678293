.Error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.Error img {
	filter: contrast(0%);
}
.Error__img--conexion img {
	width: 250px;
}
.Error__img--logo img {
	width: 350px;
}

@media (min-width: 2024px) {
	.Error__img--conexion img {
		width: 650px;
	}
	.Error__img--logo img {
		width: 650px;
	}
}

.Error__mensaje {
	color: var(--color-text);
	text-transform: capitalize;

	font-size: 20px;
}

@media (min-width: 2024px) {
	.Error__mensaje {
		font-size: 60px;
	}
}

.Error__btn {
	border: 1px solid var(--color-primary);
	padding: 1px 2px;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	padding: 5px 10px;
	transition: 0.3s ease-in-out;
}
.Error__btn:hover {
	background-color: var(--color-primary-hover);
	color: white;
}

.Error__btn img {
	transition: 0.5s ease-in-out;
	filter: contrast(100%);
	height: 20px;
}

.Error__btn:hover img {
	filter: brightness(10);
}
