.main__alumno {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr;
    background-color: var(--background-color-student);
}

.main__alumno .content__main {
    width: 100%;
    height: 90vh;
    overflow: auto;
}

.main__alumno .content__main .space {
    padding: 2rem;
    /* height: 100%; */
    /* max-height: 100%; */
}

.alumno__header {
    background-color: var(--color-primary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
}

.alumno__header .logo {
    width: 100px;
}

.alumno__header .logo img {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .alumno__header .logo {
        width: 80px;
    }
}

@media screen and (max-width: 600px) {
    .alumno__header .logo {
        display: none;
    }
}

/* Navegación */

.navegation-alumno .menu-mobile{
    background-color: transparent;
    color: #fff;
    font-size: 28px;
    display: none;
}

.navegation-alumno .menu-mobile:hover{
    cursor: pointer;
}

.navegation-alumno .list-item {
    display: flex;
    flex-direction: row;
    gap: 34px;
}



.navegation-alumno .list-item button {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: rgba(255, 255, 255, 0.568);
    position: relative;
}

.navegation-alumno .list-item .selected>button, .navegation-alumno .list-item .selected>button span {
    color: #fff;
}

.navegation-alumno .list-item button:hover, .navegation-alumno .list-item button:hover span {
    color: #fff;
    cursor: pointer;
}

.navegation-alumno .list-item button svg {
    font-size: 18px;
}

.navegation-alumno .list-item button span {
    color: rgba(255, 255, 255, 0.568);
    font-weight: 600;
    font-size: 14px;
}

.navegation-alumno .list-item button .badge {
    background-color: #D35400;
    color: #fff;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 5px;
    position: absolute;
    top: -16px;
    right: -30px;
    font-weight: 500;
}

.navegation-alumno .list-item button .badge::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 40%;
    margin-left: -12px;
    border-width: 5px;
    border-style: solid;
    border-color: #D35400 transparent transparent transparent;
}

.navegation-alumno .title-mobile{
    display: none;
}

@media screen and (max-width: 600px) {

    .navegation-alumno .list-item.no-show{
        display: none;
      
    }    

    .navegation-alumno .menu-mobile {
        display: inline-block;
    }

    .navegation-alumno .list-item {
        position: absolute;
        left: 0;
        top: 0;
        flex-direction: column;
        width: 40vw;
        min-width: 230px;
        height: 100%;
        background-color: var(--color-secundary);
        z-index: 999;
        gap: 28px;
        -webkit-box-shadow: 21px 5px 23px -24px rgba(0, 0, 0, .3);
        box-shadow: 21px 5px 23px -24px rgba(0, 0, 0, .3);
        padding: 14px 10px;
        
    }

    .navegation-alumno .list-item button {
        gap: 12px;
        background-color: transparent;
        width: 100%;
        padding: 8px 4px 8px 12px;
        border-radius: 10px;
    }
    
    .navegation-alumno .list-item .selected> button, 
    .navegation-alumno .list-item .selected> button span {
        background-color: var(--color-primary-hover);
    }
    
    .navegation-alumno .list-item button:hover,
    .navegation-alumno .list-item button:hover span {
        color: #fff;
        cursor: pointer;
       
    }
    
    .navegation-alumno .list-item button svg {
        font-size: 24px;
    }
    
    .navegation-alumno .list-item button span {
        color: rgba(255, 255, 255, 0.568);
        font-weight: 600;
        font-size: 18px;
    }
    
    .navegation-alumno .list-item button .badge {
        right: inherit;
    }

    .navegation-alumno .title-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .navegation-alumno .title-mobile .img-logo{
        width: 80px;
    }

    .navegation-alumno .title-mobile .img-logo img {
        width: 100%;
    }

    .navegation-alumno .title-mobile > button {
        display: inline-block;
        width: auto;
        gap: 12px;
        padding: inherit;
        border-radius: inherit;
    }
    
}