.indice__titulo {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.indice__titulo .title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.indice__titulo .title p {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-subtitle);
}

.indice__titulo .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    background-color: #fff;
    border-radius: 5px;
    padding: 0 16px 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    position: relative;
    min-height: 50px;
}

.indice__titulo .options .none-data{
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
    color: #C01508;
    padding: 16px 0 0;
    height: 100%;

}

.indice__titulo .options .none-data svg {
    color: #C01508;
}

.component__itemUnidad {
    position: relative;
}

div.component__itemUnidad{
    border-bottom: 1px solid #eee;
    padding-top: 16px;
    padding-bottom: 6px;
}


.component__itemUnidad .items {
    max-height: 120px;
    overflow: auto;
    padding-right: 10px;
}

.component__itemUnidad .items::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background-color: #F5F5F5;
}

.component__itemUnidad .items::-webkit-scrollbar {
    width: 3px;
    background-color: #F5F5F5;
}

.component__itemUnidad .items::-webkit-scrollbar-thumb {
    border-radius: 20px;   
    background-color: rgb(136, 134, 134);
}

.component__itemUnidad summary {
    list-style: none;
    cursor: pointer;
    margin-bottom: 16px;
    position: relative;
    
    display: grid;
    grid-template-columns: 1fr 20px
}

.component__itemUnidad summary::-webkit-details-marker {
    content: '';
    display: none;
}

summary::-webkit-details-marker {
    display: none !important;
}

.component__itemUnidad summary::before {
    content: "\276F";
    position: absolute;
    top: 15%;
    right: 8px;
    transform: rotate(0);
    transform-origin: center;
    transition: 0.2s transform ease;
}

.component__itemUnidad summary span {
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    /* text-transform: lowercase; */
}

.component__itemUnidad summary span::first-letter {
    text-transform: uppercase;
}

.component__itemUnidad[open]>summary:before {
    transform: rotate(90deg);
    transition: 0.45s transform ease;
}

details {
    overflow: hidden;
}

@keyframes details-show {
    from {
        transform: translateY(-100%);
    }
}

details>*:not(summary) {
    animation: details-show 300ms ease-in-out;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    color: transparent;
    overflow: hidden;
}

/* Componente Item Tema */

.component__itemTema label, .component__itemTema {
    width: 100%;
}

.component__itemTema {
    margin-top: 8px;
}

.component__itemTema label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    padding-left: 8px;
}

.component__itemTema label span{
    display: inline-block;
    text-transform: lowercase;
    font-size: 13px;
   /*  text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 270px; */
}

.component__itemTema label span::first-letter {
    text-transform: uppercase;
}