/*! Ktdra Digital v1 | MVP TI */

/* Queridos amigos de TI: recuerden siempre echarle ganas a esto que se llama vida adulta
ya nos volveremos a ver en otro momento, siempre aprendas cosas nuevas para que puedan convertirse en
los mejores programadores de Puebla. Los tkm <3. Se despide su amigo el Artur. */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');
/* font-family: 'Quicksand', sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;700&display=swap');
/* font-family: 'Urbanist', sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
/* font-family: 'Poppins', sans-serif; */

@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,700');
/* font-family: 'Ubuntu', sans-serif; */

*,
::after,
::before {
	box-sizing: border-box;
	font-family: 'Quicksand', sans-serif;
	margin: 0;
	padding: 0;
	text-rendering: optimizeLegibility;
	letter-spacing: 0.01rem;
}

:root {
	--color-primary: #2c65ff;
	--color-primary-hover: #2350cc;
	--color-secundary: #275ae5;
	--background-color-primary: #fefcfb;
	--background-color-secundary: #f7f7f7;
	--background-color-student: #f4f4f4;
	--color-text: rgb(48, 48, 48);
	--color-text-opacity: rgba(48, 48, 48, 0.5);
	--border-radius-input: 3px;
	--padding-content: 2.5rem 10%;
	--padding-main-content-lr: 10%;
	--color-subtitle: #a5a8b5;
	--color-btn-primary: #2c65ff;
	--color-btn-primary-hover: #275ae5;
	--color-btn-success: #02b753;
	--color-btn-delete: #ba181b;
	--color-btn-delete-hover: #c02f31;
	-color-darkmode-bg: #313131;
}

html,
body,
#root,
#root > div {
	height: 100%;
	width: 100%;
}
body.dark-mode {
    background: black;
    color: white;
}
input,
button,
select {
	outline: 0;
	border: 0;
}

h1,
h2,
h3,
h4 {
	font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
label,
span,
input,
textarea,
p {
	color: var(--color-text);
}

a,
a:visited {
	color: #2c65ff;
	text-decoration: none;
	font-weight: 700;
	cursor: pointer;
}

html {
	font-size: 14px;
}

.noselect {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

@media screen and (min-width: 320px) {
	html {
		font-size: calc(11px + 6 * ((100vw - 320px) / 680));
	}
}

@media screen and (min-width: 1000px) {
	html {
		font-size: 16px;
	}
}

/* Styles Form */

.Form {
	display: flex;
	flex-direction: column;
	gap: 18px;
}

.Form .input-group {
	display: flex;
	flex-direction: row;
	gap: 18px;
	width: 100%;
}

@media screen and (max-width: 680px) {
	.Form .input-group {
		flex-direction: column;
	}
}

.Form .input-group .input,
.Form .input-group .input input {
	width: 100%;
}

.Form .input {
	display: flex;
	flex-direction: column;
	gap: 6px;
	position: relative;
}

.Form .input input:disabled {
	background-color: #e7e6e5;
	cursor: not-allowed;
}

.Form .input label {
	font-weight: 600;
	font-size: 13px;
}

.Form .input .optional {
	display: inline-block;
	font-size: 11px;
	font-weight: 500;
	position: absolute;
	bottom: -14px;
}

.Form .input label > span {
	font-size: 12px;
	opacity: 0.7;
}

.Form .input input,
.Form .input select,
.Form .input .input-password {
	font-size: 14px;
	padding: 10px 12px;
	border-radius: var(--border-radius-input);
	border: 1px solid rgb(211, 209, 209);
	transition: border 0.25s ease-in-out;
	font-weight: 500;
	background-color: #fff;
}

.Form .input .input-password {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0;
	padding-right: 12px;
}

.Form .input .input-password input[type='password'],
.Form .input .input-password input[type='text'] {
	width: 100%;
	border: none;
}

.Form .input .input-password button {
	font-size: 24px;
	background-color: transparent;
	color: rgb(117, 115, 115);
	cursor: pointer;
}

.Form .input input:focus,
.Form .input .input-password:hover {
	border: 1px solid #2c65ff;
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.Form .input-icon .msg-error,
.Form .input .msg-error {
	color: red;
	font-weight: 500;
	font-size: 12px;
	display: inline-block;
	width: 100%;
}

.Form .input-icon .msg-error {
	text-align: right;
}

.Form .input-icon {
	padding: 10px 12px;
	border-radius: var(--border-radius-input);
	border: 1px solid rgb(211, 209, 209);
	transition: border 0.25s ease-in-out;
}

.Form .input-icon > div {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
}

.Form .input-icon > div input {
	width: 100%;
}

.Form .input-icon:hover {
	border: 1px solid #2c65ff;
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.Form .input-icon svg {
	transition: color 0.25s ease-in-out;
	color: rgb(211, 209, 209);
	font-size: 18px;
}

.Form .input-icon:hover svg {
	color: #2c65ff;
	transition: color 0.25s ease-in-out;
}

.Form .buttons-group {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Form .buttons-group.right {
	justify-content: flex-end !important;
}

.Form .buttons-group.center {
	justify-content: center !important;
}

.Form .errors {
	background-color: #f8d7da;
	padding: 16px 10px;
	border-radius: var(--border-radius-input);
}

.Form .errors p {
	color: #9d1c24;
	font-weight: 500;
	font-size: 15px;
	display: flex;
	align-items: center;
	gap: 6px;
}

.Form .buttons-group .btn {
	outline: 0;
	padding: 14px 14px;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	border-radius: var(--border-radius-input);
	cursor: pointer;
	transition: all 0.25s ease-in-out;
	position: relative;
}

.Form .buttons-group .btn.small {
	min-width: initial;
	padding: 12px 14px;
	font-size: 13px;
}

.Form .buttons-group .btn.primary {
	background-color: var(--color-btn-primary);
}

.Form .buttons-group .btn.primary:hover {
	background-color: var(--color-primary-hover);
}

.Form .buttons-group .btn:hover {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
		rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.Form .buttons-group .btn.loading {
	width: 40px;
	height: 30px;
}

.Form .buttons-group .btn.loading::after {
	content: '';
	position: absolute;
	width: 16px;
	height: 16px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	border: 4px solid transparent;
	border-top-color: #ffffff;
	border-radius: 50%;
	animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
	from {
		transform: rotate(0turn);
	}
	to {
		transform: rotate(1turn);
	}
}

/* ------------------------------------------------------------------------------- */

/* Styles Modal */

.custom__modal {
	background-color: #fff;
	min-width: 580px;
	border-radius: var(--border-radius-input);
	box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
		rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
	padding: 24px 28px;
	position: relative;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.custom__modal.w768 {
	min-width: 768px;
}

.custom__modal.h500 {
	min-height: 500px;
}

.custom__modal.maxW768px {
	max-width: 768px;
}

@media screen and (max-width: 800px) {
	.custom__modal {
		min-width: 80vw !important;
	}
}

.custom__modal.off-a {
	animation: none;
}

@media screen and (max-width: 628px) {
	.custom__modal {
		width: 90vw;
		min-width: initial;
	}
}

@media screen and (max-height: 690px) {
	.custom__modal {
		height: 90vh;
		overflow: auto;
	}
}

.modal-overlay {
	background: rgba(0, 0, 0, 0.4);
}

.custom__modal .title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.custom__modal .title .text {
	font-size: 14px;
}

.custom__modal .title .text h2 {
	margin-bottom: 6px;
}

.custom__modal .title .text p {
	font-weight: 500;
	font-size: 14px;
}

.custom__modal button.close {
	font-size: 28px;
	border-radius: 50%;
	width: 34px;
	height: 34px;
	background-color: transparent;
	font-weight: 500;
	color: var(--color-text-opacity);
	transition: color 0.2s ease-in-out;
}

.custom__modal button.close:hover {
	color: var(--color-text);
	cursor: pointer;
}

.custom__modal .content {
	padding: 18px 0;
}

.custom__modal .success {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.custom__modal .success .svg {
	width: 120px;
}

.custom__modal .success .svg img {
	width: 100%;
	height: 100%;
}

.custom__modal .success .text {
	text-align: center;
	max-width: 340px;
}

.custom__modal .success .text h2 {
	color: #02b753;
	margin: 10px 0;
}

.custom__modal .success .text p {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
	color: var(--color-subtitle);
}

.custom__modal .success .buttons {
	padding: 18px;
}

.custom__modal .success .buttons button {
	background-color: #02b753;
	color: #fff;
	padding: 12px 16px;
	border-radius: 20px;
	min-width: 180px;
	font-weight: 600;
	cursor: pointer;
}

/* ------------------------------------------------------------------------------- */

/* Effects CSS */

@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

/* ------------------------------------------------------------------------------- */

/* Titulo en contenido principal */

.title__content h2 {
	margin-bottom: 8px;
	font-size: 24px;
}

.title__content p {
	font-size: 14px;
	font-weight: 500;
	color: var(--color-subtitle);
}

/* ------------------------------------------------------------------------------- */

/* Styles Tabs */

.tabs {
}

.tabs .tabList {
	list-style: none;
	display: flex;
	flex-direction: row;
	gap: 16px;
	border-bottom: 1px solid #e7e6e5;
}

.tabs .tabList .tab {
	padding: 6px 16px 18px;
	position: relative;
	font-weight: 500;
}

.tabs .tabList .tab:hover {
	color: var(--color-btn-primary-hover);
	cursor: pointer;
}

.tabs .tabList .react-tabs__tab--selected {
	color: var(--color-primary);
}

.tabs .tabList .react-tabs__tab--selected::after {
	content: '';
	position: absolute;
	width: 100%;
	background-color: var(--color-primary);
	height: 2px;
	bottom: -1px;
	left: 0;
}

/* ------------------------------------------------------------------------------- */

/* Buttons Customs */

.__btnCustom {
	font-size: 14px;
	padding: 10px 14px;
	border-radius: var(--border-radius-input);
	cursor: pointer;
	background-color: var(--color-primary);
	color: #fff;
	width: auto;
	transition: background-color 0.25s ease-in-out;
	font-weight: 500;
}

.__btnCustom:hover {
	background-color: var(--color-primary-hover);
}

.__btnCustom.delete {
	background-color: var(--color-btn-delete);
}

.__btnCustom.delete:hover {
	background-color: var(--color-btn-delete-hover);
}

.__btnCustom:disabled {
	border: 1px solid #999999;
	background-color: #cccccc;
	color: #666666;
	cursor: not-allowed;
}

.__btnCustom:disabled:hover {
	background-color: #cccccc;
}

/* ------------------------------------------------------------------------------- */

.rpv-toolbar-right {
	display: none !important;
}

/* Scrollbar Desings */

/* ===== Scrollbar CSS ===== */
/* Firefox */
.scroll-desing {
	scrollbar-width: auto;
	cursor: pointer;
	scrollbar-color: #fff #fff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	height: 6px;
	width: 8px;
}

*::-webkit-scrollbar-track {
	background: #fff;
}

*::-webkit-scrollbar-thumb {
	background-color: #ced0d4;
	border-radius: 10px;
	border: 0px none #ffffff;
}

/* ------------------------------------------------------------------------------- */

/* Styles of Exercises */

.buttons_exercises {
	padding: 16px 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 12px;
}

.buttons_exercises .button {
	padding: 8px 12px;
	color: #fff;
	border-radius: var(--border-radius-input);
	font-weight: 500;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
}

/* Shimmer Loading Styles */

.shimmer {
	background-image: linear-gradient(
		90deg,
		#eee 0%,
		#eee 40%,
		#ddd 50%,
		#ddd 55%,
		#eee 65%,
		#eee 100%
	);
	background-size: 400%;
	animation: shimmer 1500ms infinite;
}

@keyframes shimmer {
	from {
		background-position: 100% 100%;
	}
	to {
		background-position: 0 0;
	}
}
