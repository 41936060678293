.component__temaEnProceso .content .wrapper-process .text {
    position: relative;
    font-size: 8px;
}

.component__temaEnProceso .content .wrapper-process .text span{
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    padding-bottom: 6px;
    color: var(--color-text);
}

.component__temaEnProceso .content .wrapper-process .text p{
    font-size: 14px;
    font-weight: 600;
    color: var(--color-primary);
}

.component__temaEnProceso .content .wrapper-list {
    position: relative;
    font-size: 10px;
    max-height: 160px;
    overflow: auto;
}




/* Componente ListEjercicios */
.component__listEjercicios {
    
}

.component__listEjercicios .title {
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    padding-bottom: 6px;
    color: var(--color-text);
}

.component__listEjercicios .content ul {
    list-style: none;
    padding-left: 10px;
    overflow: auto;
}

.component__listEjercicios .content ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    margin: 8px 0;
}

.component__listEjercicios .content ul li .icon{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: grid;
    place-content: center;
}

.component__listEjercicios .content ul li .icon svg {
    color: #fff;
    font-size: 90%;
}

.component__listEjercicios .content ul li .text {
    font-size: 16px;
    font-weight: 600;
}