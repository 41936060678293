.component__relColumnas  {
   display: flex;
   flex-direction: column;
}

.component__relColumnas .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 4rem;
    height: 100%;
    overflow: auto;
}

.component__relColumnas .columns .wrapper__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
}

.component__relColumnas .columns .wrapper__column .title span{
    font-weight: 600;
}

.component__relColumnas .columns .wrapper__column .cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.component__relColumnas .columns .wrapper__column .cards.anwers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 18px;
}

.component__relColumnas .columns .wrapper__column .cards .card {
    padding: 8px 12px;
    border-radius: 4px;
    font-weight: 500;
}

.component__relColumnas .columns .wrapper__column .cards .card.answer{
    color: #fff;
    white-space: nowrap;
}

.component__relColumnas .columns .wrapper__column .cards .card.answer .image_wrapper{
    max-width: 84px;
    max-height: 84px;
}

.component__relColumnas .columns .wrapper__column .cards .card.answer .image_wrapper img {
    width: 100%;
}