

/* InfoLibro Component */

.component__infoLibro ,
.loading__infoLibro{
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 12px;
}


.component__infoLibro .portada-img {
    width: 100%;
}

.component__infoLibro .portada-img img {
    width: 100%;
}

.component__infoLibro .details,
.loading__infoLibro .details  {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    gap: 4px;
}

.component__infoLibro .details h2 {
    font-size: 18px;
    margin-bottom: 6px;
}

.component__infoLibro .details h2 > span {
    font-size: 15px;
    display: inline;
    color: var(--color-text);
}


.component__infoLibro .details span {
    font-weight: 700;
    display: block;
    font-size: 13px;
    color: var(--color-text-opacity);
}

.loading__infoLibro {
    width: 100%;
    height: 200px;
    
}


.loading__infoLibro .details .h2 {
    width: 100%;
    height: 30px;
}

.loading__infoLibro .details .span {
    width: 100%;
    height: 20px;
}