.alumno__dashboard-libros {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 15px;
	width: 98%;
}

/* List Libros Styles */

.wrapper__libros {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}

.wrapper__libros .alice-carousel__dots{
	margin: 0;
}

.wrapper__libros .alice-carousel {
	cursor: grab;
}
.wrapper__libros .alice-carousel:active {
	cursor: grabbing;
}
.wrapper__libros .alice-carousel .alice-carousel__stage-item {
	padding-bottom: 12px;
	margin-right: 10px;
}
@media (max-width: 768px ) { 
	.wrapper__libros .alice-carousel .alice-carousel__stage-item {
		margin-left: 0;
	}
}

.wrapper__libros .alice-carousel .alice-carousel__stage-item:not(.__cloned) {
	width: auto !important;
}

/* Libro Styles */

.component__libro {
	width: 450px;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	padding: 15px 24px;
	cursor: grab;
	transition: all 0.2s ease-in-out;
}
.component__libro:active {
	cursor: grabbing;
}

.component__libro.disabled {
	background-color: #cccccc71;
	color: #666666;
}

@media screen and (max-width: 768px) {
	.alumno__dashboard-libros {
		justify-content: center;
		width: 98%;
		position: relative;
	}
}

.component__libro .title h2 {
	line-height: 1.1;
	text-transform: uppercase;
	font-size: 1.2rem;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	/* number of lines to show */
	-webkit-box-orient: vertical;
}

.component__libro .title span {
	color: var(--color-subtitle);
	font-size: 14px;
}

.component__libro .content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.component__libro .content .cicle_porcentage {
	max-width: 78px;
}

.component__libro .content .book_image {
	width: 100px;
}

.component__libro .content .book_image img {
	width: 100%;
}

.component__libro.disabled .content .book_image img {
	filter: grayscale(100%);
}

.component__libro .footer {
	display: flex;
	flex-direction: row;
	padding: 8px 0;
	align-items: center;
	justify-content: space-between;
}

.component__libro .footer .description {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.component__libro .footer .description span {
	font-weight: 600;
	font-size: 13px;
	display: inline-block;
	color: var(--color-text-opacity);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.component__libro .footer .description span:first-child::after {
	content: '•';
	margin: 0 5px;
}

.component__libro .footer .arrow_button button {
	background-color: transparent;
	color: var(--color-btn-primary);
	font-size: 14px;
	padding: 2px 8px;
	transition: all 0.2s ease-in-out;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.component__libro .footer .arrow_button button svg {
	font-size: 1.2rem;
}

.component__libro .footer .arrow_button span {
	font-size: 14px;
	font-weight: 500;
	display: inline-block;
	background-color: #eee;
	padding: 4px 8px;
	border-radius: 6px;
}

.component__libro .footer .arrow_button button:hover {
	cursor: pointer;
	background-color: #eee;
	border-radius: 10px;
	background-color: var(--color-btn-primary);
	color: #fff;
}

@media screen and (max-width: 1440px) {
	.component__libro {
		max-width: 380px;
		min-width: 380px;
	}
	.component__libro .content .book_image {
		width: 80px;
	}
}

@media screen and (max-width: 500px) {
	.component__libro {
		max-width: 320px;
		min-width: 320px;
	}
	.component__libro .content .book_image {
		width: 60px;
	}
	.component__libro .content .cicle_porcentage {
		max-width: 50px;
	}
	.component__libro .footer .description span {
		font-size: 11px;
	}
	.component__libro .footer .arrow_button button,
	.component__libro .footer .arrow_button span {
		font-size: 11px;
	}
}

@media screen and (max-height: 800px) {
	.component__libro .content .book_image {
		width: 70px;
	}
}

/* Title Styles */

.component__title {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.component__title h2 {
	display: inline-block;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 15px;
	color: var(--color-text-opacity);
}

.component__title button {
	font-weight: 500;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	background-color: transparent;
	padding: 3px 8px;
	border-radius: 10px;
	transition: background-color 0.2s ease-in-out;
}

.component__title button svg {
	font-size: 15px;
	color: var(--color-text);
}

.component__title button span {
	font-size: 14px;
	color: var(--color-text);
	font-weight: 600;
}

.component__title button:hover {
	background-color: rgb(228, 228, 228);
	cursor: pointer;
}
